import axios from "axios";

export const fetchData = async (baseDomain) => {
  try {
    console.log(baseDomain);
    const response = await axios.get(`${baseDomain}`);
    return response.data;
  } catch (error) {
    console.log(error); 
    throw new Error(error.message);
  }
};


export const postData = async (baseDomain, upstreamApi, req) => {
    if(!upstreamApi.includes('json-server')){
      try{
        const response = await axios.post(`${baseDomain}/place-order?upstreamApi=${upstreamApi}`, req);
        console.log('response in postData',response)
        return response;
      }
      catch(err){
        console.log('failed here in postData',err.response)
        return err.response
      }
    }
    else{
      try{
        const response = await axios.post(`${baseDomain}/place-order`, req);
        console.log('response in postData',response)
        return response;
      }
      catch(err){
        console.log('failed here in postData',err.response.data)
        return err.response
      }
    }
};