import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData } from './apiServices';
import Buy from './Buy';
import './Home.css';
import Loader from './Loader';
import { CollapsibleExample as Navbar } from './Navbar';

const Home=() =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [baseDomain, setBaseDomain] = useState(()=>{
        try{
             const initialValue = 'https://demo-apis.json-server.dev';
             const item = window.localStorage.getItem("domainValue"); 
             return item? JSON.parse(item):initialValue; 
        }
        catch(err){
          console.log(err); 
          return 'https://demo-apis.json-server.dev';
        }
    });
    const updateUpstreamApi = (newUpstreamApi) => {
      console.log("Received new upstream API in Home:", newUpstreamApi);  // Debugging log
      window.localStorage.setItem("upstreamApi", JSON.stringify(newUpstreamApi));
      setUpstreamApi(newUpstreamApi);
    };
    
    const [upstreamApi, setUpstreamApi] = useState(()=>{
      try{
        const item = window.localStorage.getItem("upstreamApi");
        return item ? JSON.parse(item) : 'https://trading.json-server.dev';
      }
      catch(err){
        console.log(err);
        return 'https://trading.json-server.dev';
      }
    });
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
  
    const loadData = async (page = 1) => {
      setData([]);
      setLoading(true);
      try {
        const baseApi = `${baseDomain}/loan-clos?page=${page}`;
        const result = await fetchData(baseApi);
   
       console.log(result);       
       const formattedData = Array.isArray(result.items) ? result.items : [result.items];
        setData(formattedData);
        setTotalPages(result.totalPages);
        setCurrentPage(result.currentPage);

        if (formattedData.length > 0) {
          toast.success('Information retrieved', {
            autoClose: 3000,
            position: "top-center",
          });
        } else {
          toast.info('No data available', {
            autoClose: 3000,
            position: "top-center",
          });
        }
      }

        catch(err){
          toast.error(err.message,{
            autoClose:3000,
            position: "top-center",
          })
        }

      finally {
        setLoading(false);
      }
    };
  
    const updateBaseDomain = (newDomain) => {
      window.localStorage.setItem("domainValue",JSON.stringify(newDomain)); 
      setBaseDomain(newDomain);
    };
    
    useEffect(() => {
      setUpstreamApi(upstreamApi);
      loadData(currentPage);  // Load data on currentPage changes or initial load
    }, [currentPage, baseDomain]);  // Include all relevant dependencies in one array

    const [title,setTitle] = useState(()=>{
      try{
        const item = window.localStorage.getItem("givenTitle"); 
       return item ? JSON.parse(item) :'CLO Listing'
     }
     catch(err){
      console.log(err); 
      return 'CLO Listing'
     }
    })
       
  
    return (
      <>
        <Navbar handleUpdateBaseDomain={updateBaseDomain} loadData={loadData} title={title} handleUpdateUpstreamApi={updateUpstreamApi}/>
        <ToastContainer />
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          data && data.length > 0 ? (
            <>
              <Container>
                <br />
                <Table striped bordered hover size='sm' className='table-sm'> 
                  <thead>
                    <tr>
                      <th style={{textAlign:'center'}}>Stock Symbol</th>
                      <th style={{textAlign:'right'}}>Current Price ($)</th>
                      <th style={{textAlign:'right'}}>24h Change</th>
                      <th style={{textAlign:'right'}}>Volume</th>
                      <th style={{textAlign:'right'}}>Market Cap ($B)</th>
                      <th style={{textAlign:'center'}}>Trade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td style={{textAlign:'center', fontWeight: 'bold'}}>{item?.symbol || '-'}</td>
                        <td style={{textAlign:'right'}}>
                          {item?.currentPrice ? `$${Number(item.currentPrice).toFixed(2)}` : '-'}
                        </td>
                        <td style={{
                          textAlign:'right',
                          color: (item?.priceChange || 0) >= 0 ? '#0ecb81' : '#f6465d'
                        }}>
                          {item?.priceChange ? `${item.priceChange > 0 ? '+' : ''}${item.priceChange}%` : '-'}
                        </td>
                        <td style={{textAlign:'right'}}>
                          {item?.volume ? Number(item.volume).toLocaleString() : '-'}
                        </td>
                        <td style={{textAlign:'right'}}>
                          {item?.marketCap ? Number(item.marketCap).toFixed(2) : '-'}
                        </td>
                        <td style={{textAlign:'center'}}>
                          <Buy data={item} baseDomain={baseDomain} upstreamApi={upstreamApi}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'10px'}}>
                <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} style={{marginRight:'10px'}}>
                    Previous
                </Button>
                <span style={{marginRight:'10px'}}> Page {currentPage} of {totalPages} </span>
                <Button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} style={{marginLeft:'10px'}}>
                    Next
                </Button>
              </div>
            </>
          ) : null
        )}
      </>
    );
}

export default Home
