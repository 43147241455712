import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function TitlePage() {

  const [newTitle,setNewTitle] = useState(()=>{
    try{
      const item = window.localStorage.getItem("givenTitle"); 
     return item ? JSON.parse(item) :'CLO Listing'
   }
   catch(err){
    console.log(err); 
    return 'CLO Listing'
   }
  }
  ); 
  const navigate = useNavigate();

  const submitHandler = (event)=>{

event.preventDefault(); 
window.localStorage.setItem("givenTitle",JSON.stringify(newTitle)); 
  toast.success('Title Updated Successfully',{
    position: "top-center",
    autoClose:3000
  })

  setTimeout(()=>{
    navigate('/');
  },1000)
  
  }

  return (
    <>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
    <Card style={{ width: '100%', maxWidth: '500px' }}>
      <Card.Body>
        <Card.Title className="text-center">Update Application Title</Card.Title>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="formBasicTitle">
            <Form.Control 
              type="text" 
              placeholder="Enter Title"
              value={newTitle}
              onChange={(e)=>{
                 setNewTitle(e.target.value); 
              }}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100" >
            Save
          </Button>
        </Form>
      </Card.Body>
    </Card>
  </Container>
  <ToastContainer />
  </>
  )
}

export default TitlePage
